import React, { useState } from "react";
import PageSubTitle from "../../components/pageSubTitle";
import {
  useGetDriverRequiredAndSubmittedDocumentsQuery,
  // useGetRequiredDocumentsQuery,
} from "../../redux/services/documentAPI";
import { Accordion, Badge, Button, Modal } from "react-bootstrap";
import moment from "moment";
import {
  BASE_URL,
  BUCKET_PUBLIC_URL,
  // SOCKET_BASE_URL
} from "../../constants";
import DocumentPlaceholderImage from "../../assets/images/document-placeholder.png";
// import DropzoneUpload from "../../components/formComponents/DropzoneUpload";
// import { useSelector } from "react-redux";
import Dropzone from "../../components/formComponents/Dropzone";
import axios from "axios";
import tokenService from "../../services/tokenService";
import { notifyFailure, notifySuccess } from "../../components/toast";

function Documents() {
  // const auth = useSelector((state) => state.auth);

  const {
    data: documents = [],
    // isLoading,
    // error,
    refetch,
  } = useGetDriverRequiredAndSubmittedDocumentsQuery();

  const [viewDocument, setViewDocument] = useState(null);
  const [updateDocumentModal, setUpdateDocumentModal] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  console.log("updateDocumentModal", updateDocumentModal);

  const submitDocumentHandler = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      notifyFailure("Please select the document!");
      return; // Prevent upload if it's disabled
    }
    console.log(updateDocumentModal);
    const formData = new FormData();

    // Append the file and other data to the form
    formData.append("file", selectedFile);
    formData.append("documentType", updateDocumentModal.documentType);
    formData.append("documentSide", updateDocumentModal.side.toLowerCase());
    if (updateDocumentModal.expirationDate) {
      formData.append("expirationDate", updateDocumentModal.expirationDate);
    }

    try {
      setIsUploading(true);
      await axios.post(`${BASE_URL}/drivers/submit-document`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${tokenService.getLocalAccessToken()}`,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });

      // Handle success
      notifySuccess("Document submitted successfully!");
      setUploadProgress(0); // Reset the progress after success
      setIsUploading(false);
      refetch();
      setUpdateDocumentModal(null);
    } catch (error) {
      // Handle error
      console.error("Error submitting document", error);
      setUploadProgress(0); // Reset the progress on error
      setIsUploading(false);
    }
  };

  return (
    <>
      <div className="px-4 py-2 border-top-0 emp-dashboard">
        <PageSubTitle pageTitle="Documents" />

        <Accordion
          className="mt-4 mb-0"
          // defaultActiveKey={["0"]}
          // alwaysOpen={true}
          defaultActiveKey={[0, 1, 2, 3]}
        >
          {documents?.map((document, index) => (
            <Accordion.Item eventKey={index}>
              {console.log(document, "document")}
              <Accordion.Header>
                <div className="d-flex justify-content-between align-items-center w-100 mr-3">
                  <div className="d-flex justify-content-center align-items-center">
                    <p className="m-0 d-flex gap-2">
                      {document?.isSubmitted &&
                      document?.frontSideUrl &&
                      document?.status === "Verified" ? (
                        <Badge bg="success" pill={true}>
                          <i class="fa fa-check" aria-hidden="true"></i>
                        </Badge>
                      ) : (
                        <Badge bg="danger" pill={true}>
                          <i class="fa fa-times" aria-hidden="true"></i>
                        </Badge>
                      )}
                      {document?.name}
                    </p>
                    {/* {document?.isSubmitted ? (
                        <Badge bg="success">Submitted</Badge>
                      ) : (
                        <Badge bg="danger">Missing</Badge>
                      )} */}
                  </div>

                  <div className="d-flex gap-4">
                    {document?.isSubmitted &&
                    document?.frontSideUrl &&
                    document?.status === "Verified" ? (
                      <Badge bg="success">Verified</Badge>
                    ) : document?.isSubmitted ? (
                      document?.status === "Pending" ? (
                        <Badge bg="info">{document?.status}</Badge>
                      ) : document?.status === "Expired" ? (
                        <Badge bg="danger">{document?.status}</Badge>
                      ) : (
                        <></>
                      )
                    ) : (
                      <Badge bg="danger">Missing</Badge>
                    )}
                    {/* <Badge
                    bg={
                      document?.status == "Pending"
                        ? "warning"
                        : document?.status == "Verified"
                        ? "success"
                        : document?.status == "Rejected"
                        ? "danger"
                        : "danger"
                    }
                  >
                    {document?.status}
                  </Badge> */}
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body className="accordion_body">
                {document?.isSubmitted ? (
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <div>
                        <h6 className="card-title ">Expiry Date: </h6>
                        {document?.hasExpiry ? (
                          <Badge
                            bg={
                              moment(
                                document?.expirationDate,
                                "YYYY-MM-DD"
                              ).isAfter(moment())
                                ? "success"
                                : "danger"
                            }
                          >
                            <p className="m-0">
                              {moment(
                                document?.expirationDate,
                                "YYYY-MM-DD"
                              ).format("MM-DD-YYYY")}
                            </p>
                          </Badge>
                        ) : (
                          <Badge bg="success">
                            <p className="m-0">No Expiry</p>
                          </Badge>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="d-flex flex-md-row flex-column gap-4 mt-3 w-100">
                  <div className="card mr-3 p-3 w-100">
                    <h6 className="card-title mr-2">
                      <span className="mr-3">Front Side</span>
                      {document?.isSubmitted && document?.frontSideUrl ? (
                        <Badge bg="success">Submitted</Badge>
                      ) : (
                        <Badge bg="danger">Missing</Badge>
                      )}
                    </h6>
                    <div className="d-flex">
                      <div
                        className=""
                        // onClick={() =>
                        //   setShow(`${BUCKET_PUBLIC_URL}/${document?.frontSideUrl}`)
                        // }
                      >
                        <img
                          src={
                            document?.frontSideUrl
                              ? `${BUCKET_PUBLIC_URL}${document?.frontSideUrl}`
                              : DocumentPlaceholderImage
                          }
                          width={"100%"}
                          height={300}
                          alt="Logo"
                          className="my-2 object-contain"
                          style={{ objectFit: "contain" }}
                        />
                      </div>

                      {/* <!-- Document Upload Input --> */}
                      {/* <div class="mb-3 col-12 col-md-5">
                      <DropzoneUpload
                        name="backSideUrl"
                        // onChange={(e) => handleChange(index, e)}
                        directory={`driver-uploads/${auth.userInfo._id}/${document?.documentType}/back/`}
                        isEnabled={
                          !document.hasExpiry ? true : document?.expirationDate
                        }
                        disableReason="Please select expiry date first!"
                      />
                    </div> */}
                    </div>

                    <div className="d-flex w-100 gap-4 my-3">
                      {document.isSubmitted && (
                        <Button
                          className="w-50"
                          variant="success"
                          onClick={() =>
                            setViewDocument({
                              name: document.name,
                              side: "Front",
                              imageUrl: `${BUCKET_PUBLIC_URL}${document?.frontSideUrl}`,
                              hasBackSide: document?.hasBackSide,
                            })
                          }
                        >
                          View
                        </Button>
                      )}
                      <Button
                        className="w-50"
                        onClick={() =>
                          setUpdateDocumentModal({
                            name: document.name,
                            side: "Front",
                            imageUrl: `${BUCKET_PUBLIC_URL}${document?.frontSideUrl}`,
                            hasBackSide: document?.hasBackSide,
                            documentId: document.documentId,
                            hasExpiry: document?.hasExpiry,
                            expirationDate: document?.expirationDate,
                            documentType: document._id,
                          })
                        }
                      >
                        {document.isSubmitted ? "Update" : "Submit"}
                      </Button>
                    </div>
                  </div>

                  {document?.hasBackSide ? (
                    <div className="card mr-3 p-3 w-100">
                      <h6 className="card-title mr-2 ">
                        <span className="mr-3">Back Side</span>
                        {document?.isSubmitted && document?.backSideUrl ? (
                          <Badge bg="success">Submitted</Badge>
                        ) : (
                          <Badge bg="danger">Not submitted</Badge>
                        )}
                      </h6>{" "}
                      <div
                        className=""
                        // onClick={() =>
                        //   setShow(`${BUCKET_PUBLIC_URL}/${document?.backSideUrl}`)
                        // }
                      >
                        <img
                          src={
                            document?.backSideUrl
                              ? `${BUCKET_PUBLIC_URL}${document?.backSideUrl}`
                              : DocumentPlaceholderImage
                          }
                          // width={300}
                          height={300}
                          alt="Logo"
                          className="my-2 object-contain"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                      <div className="d-flex w-100 gap-4 my-3">
                        <Button
                          className="w-50"
                          variant="success"
                          onClick={() =>
                            setViewDocument({
                              name: document.name,
                              side: "Back",
                              imageUrl: `${BUCKET_PUBLIC_URL}${document?.backSideUrl}`,
                              hasBackSide: document?.hasBackSide,

                              hasExpiry: document?.hasExpiry,
                              documentType: document._id,
                            })
                          }
                        >
                          View
                        </Button>
                        <Button
                          className="w-50"
                          onClick={() =>
                            setUpdateDocumentModal({
                              name: document.name,
                              side: "Back",
                              imageUrl: `${BUCKET_PUBLIC_URL}${document?.backSideUrl}`,
                              hasBackSide: document?.hasBackSide,
                              documentId: document.documentId,
                              hasExpiry: document?.hasExpiry,
                              expirationDate: document?.expirationDate,
                              documentType: document._id,
                            })
                          }
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      </div>

      {/* View Modal */}
      {viewDocument && (
        <Modal
          show={viewDocument}
          onHide={() => setViewDocument(null)}
          centered
        >
          <div className="small-modal">
            <Modal.Header className="d-flex justify-content-between">
              <h5>
                {viewDocument.name}{" "}
                {viewDocument.hasBackSide && <>({viewDocument.side})</>}
              </h5>
              {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
            </Modal.Header>
            <Modal.Body>
              <img
                src={viewDocument.imageUrl}
                width={"100%"}
                // height={300}
                alt="Logo"
                className="my-2 object-contain"
                style={{ objectFit: "contain" }}
              />
            </Modal.Body>
          </div>
        </Modal>
      )}
      {/* UPDATE Modal */}
      {updateDocumentModal && (
        <Modal
          show={updateDocumentModal}
          // onHide={() => setUpdateDocumentModal(null)}
          centered
        >
          <div className="small-modal">
            <Modal.Header className="d-flex justify-content-between">
              <h5>Submit Document</h5>
              {/* <h6 className="text-danger">{formattedTimeLeft}</h6> */}
            </Modal.Header>
            <Modal.Body>
              <form onSubmit={submitDocumentHandler}>
                {/* <!-- Document Name Input --> */}

                <div className="row">
                  <div class="mb-3 col-12">
                    <label for="documentName" class="form-label">
                      Document Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="documentName"
                      name="documentName"
                      required
                      value={`${updateDocumentModal.name} ${
                        updateDocumentModal.hasBackSide
                          ? `(${updateDocumentModal.side})`
                          : ""
                      }`}
                      disabled
                    />
                  </div>
                  {/* <!-- Expiry Date Input --> */}
                  {updateDocumentModal.hasExpiry &&
                    updateDocumentModal.side === "Front" && (
                      <div class="mb-3 col-12">
                        <label for="expiryDate" class="form-label">
                          Expiry Date
                        </label>
                        <input
                          type="date"
                          class="form-control"
                          id="expiryDate"
                          name="expirationDate"
                          defaultValue={moment(
                            updateDocumentModal.expirationDate
                          ).format("YYYY-MM-DD")}
                          required
                          // onChange={(e) => handleChange(index, e)}
                        />
                      </div>
                    )}

                  {/* <!-- Document Upload Input --> */}
                  <div class="mb-5 col-12">
                    <label for="documentName" class="form-label">
                      Upload Document
                    </label>
                    {/* <DropzoneUpload
                      name="backSideUrl"
                      // onChange={(e) => handleFile(e)}
                      directory={`driver-uploads/${auth.userInfo._id}/${updateDocumentModal?.documentId}/back/`}
                      isEnabled={
                        !updateDocumentModal.hasExpiry
                          ? true
                          : updateDocumentModal?.expirationDate
                      }
                      disableReason="Please select expiry date first!"
                    />{" "} */}
                    <Dropzone
                      isUploading={isUploading}
                      uploadProgress={uploadProgress}
                      fileSelectHandler={(value) => setSelectedFile(value)}
                    />
                  </div>
                  {/* <div className="mb-3 col-12 col-md-2">
                    {updateDocumentModal?.imageUrl && (
                      <img
                        src={updateDocumentModal?.imageUrl}
                        height={150}
                        alt=""
                      />                
                    )}
                  </div> */}
                </div>
                {/* <!-- Submit Button --> */}
                <div className="d-flex gap-4 w-100 justify-content-between">
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={() => setUpdateDocumentModal(null)}
                  >
                    Close
                  </button>
                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
}

export default Documents;
